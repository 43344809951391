import React from "react";
import { Root } from "./style";

import RawHTML from "components/Common/RawHTML";
import ShareLinks from "components/Common/ShareLinks";
import JoinTheCommunities from "components/Common/JoinTheCommunities";

import vars from "variables";

const HODLForwardCase = ({ location, pageContext: { hodlForwardCase } }) => {
  return (
    <Root className="body-width">
      {hodlForwardCase.dailyMotionID ? (
        <div
          style={{
            margin: "90px 0",
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
            overflow: "hidden",
            borderRadius: "30px",
          }}
        >
          <iframe
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              left: "0px",
              top: "0px",
              overflow: "hidden",
            }}
            frameBorder="0"
            type="text/html"
            src={`https://www.dailymotion.com/embed/video/${hodlForwardCase.dailyMotionID}`}
            width="100%"
            height="100%"
            allowFullScreen
            allow="autoplay"
          ></iframe>
        </div>
      ) : (
        <div
          className="image"
          style={{
            backgroundImage: `url(${vars.assetsPrefix}/${hodlForwardCase.image})`,
          }}
        />
      )}
      <h1>{hodlForwardCase.title}</h1>
      <ShareLinks location={location} item={hodlForwardCase} />
      <RawHTML className="content" html={hodlForwardCase.content} />
      <JoinTheCommunities />
    </Root>
  );
};

export default HODLForwardCase;
